/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { CustomTable } from 'common/CustomTable/CustomTable';
import { Paper } from '@mui/material'
import { CustomTableTitleAndAlert } from '../../common'
import { useExpanded } from '../../components/layouts/ProtectedLayout';
import { useConfigService, useQueryAgencyManagement, useSetConfig } from 'hooks';
import { AddNewAgencyModal, EditAgencyDrawer } from './components/index';
import { Order } from '../../customTypes/OrderType';
import { pageStyles } from 'customStyles/common';
import { useTableStore } from 'state/TableStore';
import { extractPageName } from 'common/CustomTable/helpers';
import { useLocation } from 'react-router-dom';
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/index';
import { useAgencyManagementStore } from './helpers/AgencyManagementStore';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const AgencyManagement = () => {
  const { expanded } = useExpanded();
  const userConfig = useUserStore(state => state.userConfig)
  const { usersPermissions } = userConfig
  const canAddAgencies = usersPermissions?.addNewAgencies
  const editExistingAgencies = usersPermissions?.editExistingAgencies
  console.log('agency management usersPermissions', usersPermissions)

  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('agencyName');
  const [editAgency, setEditAgency] = useState<any>()

  const openNotification = useTableStore((state) => state.openNotification)
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const setSeverity = useTableStore(state => state.setSeverity);
  const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
  const searchParams = useTableStore((state) => state.searchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)

  const isPost = useTableStore((state) => state.isPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const configFile = useTableStore((state) => state.configFile)
  const configBody = useTableStore((state) => state.configBody)
  const { searchData } = useConfigService(configFile, configBody, true, isPost, isDelete);
  const tableConfig = useTableStore(state => state.tableConfig)
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)

  // agency management state:
  const newAgencyForm = useAgencyManagementStore(state => state.newAgencyForm);
  const tableFilters = useAgencyManagementStore(state => state.tableFilters);
  const openModal = useAgencyManagementStore(state => state.openModal);
  const setOpenModal = useAgencyManagementStore(state => state.setOpenModal);
  const newAgencyInfo = useAgencyManagementStore(state => state.newAgencyInfo);
  const setNewAgencyInfo = useAgencyManagementStore(state => state.setNewAgencyInfo);
  const openAgencyEdit = useAgencyManagementStore(state => state.openAgencyEdit);
  const setOpenAgencyEdit = useAgencyManagementStore(state => state.setOpenAgencyEdit);
  const selectedRowText = useAgencyManagementStore(state => state.selectedRowText);
  const setSelectedRowText = useAgencyManagementStore(state => state.setSelectedRowText);
  const setUpdatedAgency = useAgencyManagementStore(state => state.setUpdatedAgency);
  const setAddedAgency = useAgencyManagementStore(state => state.setAddedAgency);
  const setError = useAgencyManagementStore(state => state.setError);
  const setOriExists = useAgencyManagementStore(state => state.setOriExists);
  const oriExists = useAgencyManagementStore(state => state.oriExists);
  const setRfpExists = useAgencyManagementStore(state => state.setRfpExists);
  const rfpExists = useAgencyManagementStore(state => state.rfpExists);
  const setCanAddAgency = useAgencyManagementStore(state => state.setCanAddAgency);
  const setFieldError = useAgencyManagementStore(state => state.setFieldError);
  const setTouchFields = useAgencyManagementStore(state => state.setTouchFields);
  const setAlreadyExistError = useAgencyManagementStore(state => state.setAlreadyExistError);
  const setEdited = useAgencyManagementStore(state => state.setEdited);
  const setSearched = useAgencyManagementStore(state => state.setSearched);
  const searched = useAgencyManagementStore(state => state.searched);
  const setValue = useAgencyManagementStore(state => state.setValue);
  const value = useAgencyManagementStore(state => state.value);
  const data = useAgencyManagementStore(state => state.data);
  const setData = useAgencyManagementStore(state => state.setData);

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.AGENCY_MANAGEMENT, {})

  const {
    mappedData,
    totalRecords,
    countError,
    loading, }: any = useQueryAgencyManagement(
      rowsPerPage,
      page,
      sortOrder,
      orderBy,
      tableFilters,
      searchParams)


  useEffect(() => {
    setPagination({ ...pagination, [TableNames.AGENCY_MANAGEMENT]: { page: 1, rowsPerPage: 25 } })
  }, [])


  useEffect(() => {
    console.log('mappedData: ', mappedData)
    setData(
      mappedData?.map(({ json_data: agency }: any, isActive: any) => {
        return {
          key: uuid(),
          id: agency.id,
          ori: agency.ori,
          secondaryOri: agency.secondaryOri,
          reasonFingerPrinted: agency.reasonFingerPrinted,
          agencyName: agency.agency_name,
          distributionMethod: agency.distribution_method,
          agencyEmail: agency.agency_email,
          agencyAddress: agency.agency_address,
          agencyCity: agency.agency_city,
          agencyCounty: agency.agency_county,
          agencyState: agency.agency_state,
          agencyZipcode: agency.agency_zipcode,
          agencyStatus: agency.isActive ? 'Active' : 'Inactive'
        };
      })
    );
  }, [mappedData, mappedData?.length]);

  const allFieldsFilled = Object.values(newAgencyForm).every(value => value === true);

  useEffect(() => {
    if (allFieldsFilled) {
      setCanAddAgency(true);
    } else {
      setCanAddAgency(false);
    }
    if (Object.values(selectedRowText).includes('')) {
      setEdited(true);
    } else {
      setEdited(false);
    }
  }, [newAgencyInfo, Object.keys(newAgencyInfo).length])

  useEffect(() => {
    let oriExistsTemp = false;
    let rfpExistsTemp = false;

    data?.forEach((agency: any) => {
      if (agency?.ori?.toLowerCase() === newAgencyInfo?.["ori"]?.toLowerCase()) {
        oriExistsTemp = true;
      }
      if (agency?.reasonFingerPrinted?.toLowerCase() === newAgencyInfo?.["reasonFingerPrinted"]?.toLowerCase()) {
        rfpExistsTemp = true;
      }
    });

    setOriExists(oriExistsTemp);
    setRfpExists(rfpExistsTemp);

    return () => {
      setOriExists(false);
      setRfpExists(false);
    };
  }, [newAgencyInfo, data?.ori, data?.reasonFingerPrinted]);

  useEffect(() => {
    if (oriExists && rfpExists) {
      setAlreadyExistError('Agency ORI/RFP combination already exists');
    } else {
      setAlreadyExistError('');
    }
  }, [oriExists, rfpExists]);

  const resetAgencyForm = (form: any) => {
    Object.keys(form).forEach(key => {
      form[key] = false
    })
  }

  const handleCancel = () => {
    setNewAgencyInfo({});
    setOpenModal(false);
    setOpenAgencyEdit(false);
    setError('');
    setOriExists(false);
    setRfpExists(false);
    setAlreadyExistError('');
    setFieldError(false);
    resetAgencyForm(newAgencyForm)
  }

  useEffect(() => {
    const headerButtonConfig = {
      handler: () => setOpenModal(true),
      headerButtonIcon: null,
      buttonLabel: "",
      disabled: !canAddAgencies,
      text: "ADD AGENCY"
    }
    setHeaderButtonConfig(headerButtonConfig)
  })


  const handleTextFieldError = (e: any, fieldName: any) => {
    if (e.target.value === '' && fieldName !== 'secondaryOri' && fieldName !== 'agencyEmail') {
      setTouchFields({
        [fieldName]: true
      })
      setFieldError(true);
      setError('This is a required field.');
      setAlreadyExistError('');
    }
  }

  console.log('debug data in agency:', mappedData);

  const handleRequestSort = (event: any, property) => {
    console.log(`custom table agency-management handleRequestSort current sort ${JSON.stringify({
      orderBy,
      sortOrder
    })}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`custom table agency-management handleRequestSort new sort ${JSON.stringify({
      sortOrder: isAsc ? 'desc' : 'asc',
      orderBy: property
    })}`)
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('debug openNotification:', openNotification);
    setTimeout(() => {
      setOpenNotification(false);
      setUpdatedAgency(false);
      setAddedAgency(false);
      setAlertTitle("")
      setAlertMessage("")
      setSeverity("")
    }, 7500)
  }, [openNotification])

  const handleEditAgency = ((e: any, row: any) => {
    e.stopPropagation()
    setOpenAgencyEdit(true)
    setSelectedRowText(row)
    setEditAgency(() => deepClone(row))
  })

  return (
    <>
      {

        <Paper elevation={0} sx={{ ...pageStyles.pagePaperStyle, minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})` }}>
          <CustomTableTitleAndAlert name="Agency Management" />
          {
            usersPermissions?.editExistingAgencies &&
            <EditAgencyDrawer
              tableHeaderCells={tableHeaderCells}
              handleTextFieldError={handleTextFieldError}
              handleCancel={handleCancel}
              currentAgency={editAgency}
              loading={loading}
            />
          }
          {
            usersPermissions?.addNewAgencies &&
            <AddNewAgencyModal
              tableHeaderCells={tableHeaderCells}
              handleTextFieldError={handleTextFieldError}
              handleCancel={handleCancel}
            />
          }
          {
            tableHeaderCells && (
              <CustomTable
                //Required props
                tableConfig={tableConfig}
                tableName="agency-management"
                loading={loading}
                rows={data}
                orderBy={orderBy}
                order={sortOrder}
                totalRecords={totalRecords}
                currentPage={page}
                pageSize={rowsPerPage}
                searched={searched}
                value={value}
                handleRequestSort={handleRequestSort}
                setValue={setValue}
                setSearched={setSearched}
                //Per page props        
                defaultSortColumn="ori"
                headerButtonAriaLabel='ADD AGENCY'
                headerButtonText="ADD AGENCY"
                rowClickHandler={editExistingAgencies ? handleEditAgency : () => { }}
                openAgencyEdit={openAgencyEdit}
                openModalBlur={openModal}
              />
            )
          }

        </Paper>
      }
    </>

  )
}
