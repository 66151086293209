import { EditUserAction } from "./Actions"

export const editUserReducer = (state: any, action: any) => {
    console.log('debug select update editUserReducer action: ', action)
    switch (action.type) {
        case EditUserAction.SET_CURRENT:
            const row = action.value
            const { fullName, status, securityLevel } = row
            let nameArray = fullName.split(' ')
            let firstName = nameArray[0]
            let lastName = nameArray[1]
            return {
                firstName,
                lastName,
                userName: row.loginName,
                email: row.emailAddress,
                status,
                securityLevel,
                oriRfpGroups: row.oriRfpGroups
            }
        case EditUserAction.FIRST_NAME:
            console.log('debug addOriRfp  update first name state: ', {
                ...state,
                firstName: action.value
            })
            return {
                ...state,
                firstName: action.value
            }
        case EditUserAction.LAST_NAME:
            return {
                ...state,
                lastName: action.value
            }
        case EditUserAction.USER_NAME:
            return {
                ...state,
                userName: action.value
            }
        case EditUserAction.EMAIL:
            return {
                ...state,
                email: action.value
            }
        case EditUserAction.STATUS:
            console.log('debug select editUseReducer Case: Status')
            return {
                ...state,
                status: action.value
            }
        case EditUserAction.SECURITY_LEVEL:
            return {
                ...state,
                securityLevel: action.value
            }
        case EditUserAction.ORI_RFP_GROUP:
            const oriRfpGroups = state.oriRfpGroups
            oriRfpGroups[action.group.index][action.group.key].value = action.value
            console.log('debug addOriRfp editUser EditUserAction.ORI_RFP_GROUP return state: ', { ...state })
            return {
                ...state,
                oriRfpGroups

            }
        case EditUserAction.ADD_ORI_RFP_GROUP:
            console.log('debug addOriRfp editUser EditUserAction.ADD_ORI_RFP_GROUP return state: ', {
                ...state,
                oriRfpGroups: action.value

            })

            return {
                ...state,
                oriRfpGroups: action.value
            }

        case EditUserAction.REMOVE_ORI_RFP_GROUP:
            state.oriRfpGroups.splice(action.group.index, 1)
            return {
                ...state
            }

        case EditUserAction.REMOVE_EMPTY_ORI_RFP_GROUP:
            const emptyIndexes: Array<number> = []
    
            if (state.oriRfpGroups)
                state.oriRfpGroups.forEach((group, index) => {
                    if (group?.new) {
                        if (group.primaryOri && group.primaryOri.value.length < 1) {
                            emptyIndexes.push(index)
                        } else if (group.secondaryOri && group.secondaryOri.value.length < 1) {
                            emptyIndexes.push(index)
                        }
                    }
                })
            if (emptyIndexes.length > 0) {
                state.oriRfpGroups.splice(emptyIndexes[0])
            }

            return {
                ...state
            }
        case EditUserAction.RESET_ORI_RFP_GROUP: 
            const removedOriRfp: {removed: any, index: number}[] = action.removedOriRfp
            if(removedOriRfp) {
                removedOriRfp.forEach((group) => {
                    let removed = group.removed
                    let index = group.index
                    state.oriRfpGroups.splice(index, 0, removed)
                })
                 
            }
            return {
                ...state
            }
            
        //break;
    }
}