import React, { useEffect } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Typography,
  TextField,
  Container,
  IconButton,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Paper
} from '@mui/material'
import { InputWrapper } from '../../../common'
import { AddUserOriRfp } from './AddUserOriRfp'
import { AddUserAction } from '../helpers/AddUserActions';
import { useUserManagementStore } from '../state';
import { UserRole } from 'interfaces/config';
import { useTableStore } from '../../../state/TableStore';

export const AddNewUser: React.FC<any> = ({ formState, rfpListData }) => {
  console.log('formSTate: ', formState)
  const { firstName, lastName, userName, email, oriRfpGroups, securityLevel } = formState[0]
  const dispatchFormValues = formState[1]

  console.log('dispatchformvalues', dispatchFormValues)

  const setDisableAddUser = useUserManagementStore(state => state.setDisableAddUser)
  const rfpOptions = JSON.parse(rfpListData)
  const tableConfig = useTableStore(state => state.tableConfig)
  const { config } = tableConfig["user-management"];
  const userRoles = config.cognito.userRoles


  useEffect(() => {
    if (firstName && lastName && userName && email && oriRfpGroups[0].primaryOri.value && oriRfpGroups[0].rfp.value) {
      console.log('orirfpgroups right here', oriRfpGroups)
      setDisableAddUser(false)
    } else {
      setDisableAddUser(true)
    }
  }, [email, firstName, lastName, userName, oriRfpGroups[0].primaryOri.value, oriRfpGroups[0].rfp.value])

  const CustomPaper = (props: any) => {
    return <Paper {...props} sx={{backgroundColor: 'background.default'}}/>
  }

  return (
    <Container disableGutters sx={{ height: "400px", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
      <Typography variant='subtitle2' sx={{ marginTop: "10px" }}>User Information</Typography>
      <InputWrapper customWidth="1150px" customMarginTop="10px">
        <TextField
          required
          label="First Name"
          name="firstName"
          value={firstName}
          placeholder="First Name"
          variant="outlined"
          sx={{ minWidth: "calc(100% - 540px)", height: "56px" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: "First_Name", value: e.target.value })}
        />
        <TextField
          required
          label="Last Name"
          name="lastName"
          value={lastName}
          placeholder="Last Name"
          variant="outlined"
          sx={{ minWidth: "calc(100% - 580px)", height: "56px" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: "Last_Name", value: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper customWidth="1150px" customMarginTop="10px" >
        <TextField
          required
          label="Username"
          name="userName"
          value={userName}
          placeholder="Username"
          variant="outlined"
          sx={{ minWidth: "calc(100% - 540px)", height: "56px" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: "User_Name", value: e.target.value })}
        />
        <TextField
          required
          label="Email"
          name="email"
          value={email}
          placeholder="Email"
          variant="outlined"
          sx={{ minWidth: "calc(100% - 580px)", height: "56px" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: "Email", value: e.target.value })}
        />

      </InputWrapper>
      <InputWrapper customWidth="1150px" customMarginTop="10px">
        <FormControl sx={{ minWidth: "100%", height: "56px" }}>
          <InputLabel color="secondary">Security Level</InputLabel>
          <Select
            value={securityLevel}
            onChange={(e: any) => dispatchFormValues({ type: "Security_Level", value: e.target.value })}
            variant="outlined"
            // sx={editUserTextField}
            label="Security Level">
            {
              userRoles.map((role: UserRole) => {
                console.log('debug securityLevel displaty role: ', role)
                return (
                  <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </InputWrapper>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
        <Typography variant='subtitle2' >ORI's & RFP's</Typography>
        <AddUserOriRfp
          formState={formState}
          actionType={AddUserAction.ADD_ORI_RFP_GROUP}
        />
      </Grid>
      {
        oriRfpGroups.map((oriGroup, index) => {
          console.log('debug oriRfpGroups AddNew group map: ', oriGroup, index)
          return (
            <InputWrapper customWidth="1150px" customMarginTop='10px'>
              {
                Object.keys(oriGroup).map((key, i) => {
                  console.log('oriGroup[key].value', oriGroup[key].value)
                  if (key !== "new") return (
                    <>
                      {
                        key === "primaryOri" && (
                          <TextField
                            required
                            key={index}
                            label="Primary ORI"
                            name={oriGroup[key].field}
                            value={oriGroup[key].value}
                            placeholder={oriGroup[key].placeholder}
                            variant="outlined"
                            sx={{ minWidth: "calc(100% - 540px)", height: "56px" }}
                            onChange={(e: any) => dispatchFormValues({ type: AddUserAction.ORI_RFP_GROUP, value: e.target.value, group: { index, key } })} />
                        )
                      }
                      {
                        key === "secondaryOri" && (
                          <TextField
                            key={index}
                            label="Secondary ORI"
                            name={oriGroup[key].field}
                            value={oriGroup[key].value}
                            placeholder={oriGroup[key].placeholder}
                            variant="outlined"
                            sx={{ minWidth: "calc(100% - 540px)", height: "56px" }}
                            onChange={(e: any) => dispatchFormValues({ type: AddUserAction.ORI_RFP_GROUP, value: e.target.value, group: { index, key } })} />
                        )
                      }
                      {
                        key === "rfp" && (
                            <Autocomplete
                              id={`id-${i}`}
                              sx={{ minWidth: "calc(100% - 580px)"}}
                              options={rfpOptions}
                              value={rfpOptions.find(option => option.code === oriGroup[key].value) || null}
                              getOptionLabel={(option: any) => option.rfp || ""}
                              PaperComponent={CustomPaper}
                              ListboxProps={{ style: { maxHeight: "30vh" } }}
                              renderOption={(props, option: any) => (
                                <MenuItem
                                  {...props}
                                  key={option.code}
                                  sx={{
                                    backgroundColor: 'background.default',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                    whiteSpace: 'normal'
                                  }}
                                >
                                  {option.rfp}
                                </MenuItem>
                              )}
                              onChange={(e, newValue) => dispatchFormValues({ type: AddUserAction.ORI_RFP_GROUP, value: newValue === null ? "" : newValue.code, group: { index, key } })}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={oriGroup[key].required}
                                  label="RFP"
                                  placeholder={oriGroup[key].placeholder}
                                  variant="outlined"
                                  sx={{ minWidth: "calc(100% - 580px)", height: "56px" }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {oriGroup.new && (
                                          <IconButton
                                            onClick={() =>
                                              dispatchFormValues({
                                                type: AddUserAction.REMOVE_ORI_RFP_GROUP,
                                                group: { index, key }
                                              })
                                            }
                                            sx={{ position: 'absolute', left: '100%' }}
                                          >
                                            <RemoveCircleOutlineIcon color='primary' />
                                          </IconButton>
                                        )}
                                        {params.InputProps.endAdornment}
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              )}
                            />
                        )
                      }
                    </>
                  )
                })
              }
            </InputWrapper>
          )
        })
      }
    </Container>
  )
}