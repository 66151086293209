
import {
    Box,
    Button,
    Container,
    FormControl,
    Autocomplete,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Paper,
    CircularProgress
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { InputWrapper } from '../../../common'
import { EditUserAction } from '../helpers';
import { AddUserOriRfp } from "./AddUserOriRfp"
import CancelIcon from '@mui/icons-material/Cancel';
import { UserRole } from 'interfaces/config';
import { useTableStore } from '../../../state/TableStore';
import { useUserManagementStore } from '../state';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const EditUserDrawerContent: React.FC<any> = ({ formState, handleCancel, handleUpdate, loading, rfpListData }) => {
    const { firstName, lastName, email, userName, status, securityLevel, oriRfpGroups } = formState[0]
    const [selectedUser, setSelectedUser] = useState(() => deepClone(formState[0]));
    const [edited, setEdited] = useState(false);
    const [isError, setIsError] = useState(false);
    const removedOriRfp = useUserManagementStore(state => state.removedOriRfp)
    const setRemovedOriRfp = useUserManagementStore(state => state.setRemovedOriRfp)


    useEffect(() => {
        const isEdited = Object.keys(formState[0]).some((key) => {
            if (key === "oriRfpGroups") {
                return oriRfpGroups.some((group: any, index: number) => {
                    
                    if(group.new && group.hasOwnProperty("secondaryOri") ) {
                        return (
                            group.primaryOri?.value.length > 0 && group.rfp?.value.length > 0 && group.secondaryOri?.value.length > 0
                        )
                        
                    } else if (group.new && !group.hasOwnProperty("secondaryOri")) {
                        return (
                            group.primaryOri?.value.length > 0 && group.rfp?.value.length > 0 
                        )
                    } else {
                        return (
                            group.primaryOri?.value !== selectedUser[key][index]?.primaryOri?.value ||
                            group.rfp?.value !== selectedUser[key][index]?.rfp?.value ||
                            group.secondaryOri?.value !== selectedUser[key][index]?.secondaryOri?.value
                        );
                    }
                    
                });
            } else {
                return selectedUser?.[key] !== formState[0][key];
            }
        });

        if (isEdited) {
            setEdited(true);
        } 
            

    }, [formState[0], oriRfpGroups, removedOriRfp]);


    console.log(`debug securityLevel ${securityLevel}`)
    const dispatchFormValues = formState[1]
    const tableConfig = useTableStore(state => state.tableConfig);

    const { config } = tableConfig["user-management"];
    const userRoles = config.cognito.userRoles

    const rfpOptions = JSON.parse(rfpListData ? rfpListData : '{}')

    const editUserTextField = {
        width: "268px"
    } 

    const oriRfpField = {
        width: "100%"
    }

    const sectionHeader = { fontWeight: "500", minWidth: "108px", height: "25px", fontSize: "16px", lineHeight: "25.12px", letter: "0.1px" }

    const cancelEdit = useCallback(() => {
        handleCancel(); 
        if(removedOriRfp.length > 0) {
            dispatchFormValues({ type: EditUserAction.RESET_ORI_RFP_GROUP, removedOriRfp})
            setRemovedOriRfp([])
        }
    }, [removedOriRfp])

    const CustomPaper = (props: any) => {
        return <Paper {...props} sx={{ backgroundColor: 'background.default' }} />
    }


    return (
        <Container disableGutters sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Container disableGutters sx={{ display: "flex", flexDirection: "column", width: "100%", height: !isError ? "292px" : "325px", justifyContent: "space-evenly" }}>
                <Typography sx={sectionHeader}>
                    User Information
                </Typography>
                <InputWrapper customWidth="552px">
                    <TextField
                        label="First Name"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        variant="outlined"
                        error={!firstName && isError}
                        helperText={!firstName && isError ? "First Name Field Cannot Be Empty" : ""}
                        sx={editUserTextField}
                        onChange={(e: any) => {
                            if (e.target.value === "") {
                                setIsError(true)
                            } else {
                                setIsError(false);
                            }
                            dispatchFormValues({ type: EditUserAction.FIRST_NAME, value: e.target.value })
                        }
                        } />
                    <TextField
                        label="Last Name"
                        name="lastName"
                        value={lastName}
                        error={!lastName && isError}
                        helperText={!lastName && isError ? "Last Name Field Cannot Be Empty" : ""}
                        placeholder="Last Name"
                        variant="outlined"
                        sx={editUserTextField}
                        onChange={(e: any) => {
                            if (e.target.value === "") {
                                setIsError(true)
                            } else {
                                setIsError(false);
                            }
                            dispatchFormValues({ type: EditUserAction.LAST_NAME, value: e.target.value })
                        }} />
                </InputWrapper>
                <InputWrapper customWidth="552px">
                    <TextField
                        disabled={true}
                        label="Username"
                        name="userName"
                        value={userName}
                        placeholder="Username"
                        variant="outlined"
                        sx={editUserTextField}
                        onChange={(e: any) => dispatchFormValues({ type: EditUserAction.USER_NAME, value: e.target.value })} />
                    <TextField
                        label="Email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        variant="outlined"
                        sx={editUserTextField}
                        error={!email && isError}
                        helperText={!email && isError ? "Email Field Cannot Be Empty" : ""}
                        onChange={(e: any) => {
                            if (e.target.value === "") {
                                setIsError(true)
                            } else {
                                setIsError(false);
                            } 
                            dispatchFormValues({ type: EditUserAction.EMAIL, value: e.target.value })
                        }} />
                </InputWrapper>
                <InputWrapper customWidth="552px">
                    <FormControl>
                        <InputLabel color="secondary">Status</InputLabel>
                        <Select
                            value={status}
                            onChange={(e: any) => dispatchFormValues({ type: EditUserAction.STATUS, value: e.target.value })}
                            variant="outlined"
                            sx={editUserTextField}
                            label="Status">
                            <MenuItem value={"Active"}>Active</MenuItem>
                            <MenuItem value={"Inactive"}>Inactive</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel color="secondary">Security Level</InputLabel>
                        <Select
                            value={securityLevel}
                            onChange={(e: any) => dispatchFormValues({ type: EditUserAction.SECURITY_LEVEL, value: e.target.value })}
                            variant="outlined"
                            sx={editUserTextField}
                            label="Security Level">
                            {
                                userRoles.map((role: UserRole) => {
                                    console.log('debug securityLevel displaty role: ', role)
                                    return (
                                        <MenuItem key={role.value} value={role.label}>{role.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                </InputWrapper>
            </Container>

            <Container disableGutters sx={{ height: "60%" }}>
                {
                    <Container disableGutters sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Container disableGutters sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "552px" }}>
                            <Typography sx={sectionHeader}>
                                ORI's & RFP's
                            </Typography>
                            {/*<Button startIcon={<AddIcon/>} sx={{height: "22px"}}>ADD ORI/RFP GROUP</Button>*/}
                            <AddUserOriRfp actionType={EditUserAction.ADD_ORI_RFP_GROUP} formState={formState} />
                        </Container>
                        <Container disableGutters sx={{ display: "flex", flexDirection: "column", width: "100%", height: "80%", overflowY: "auto", pt: "20px" }}>
                            {
                                oriRfpGroups?.map((oriGroup, index) => {
                                    console.log('debug oriRfpGroups Edit group map: ', { oriGroup, index, oriRfpGroups})
                                    return  (
                                        <InputWrapper customMarginTop="20px" customMargin="0px 0px 20px 0px" key={index} customWidth="552px">
                                            {
                                                Object.keys(oriGroup).map((key) => {
                                                    console.log('oriGroup[key].value', oriGroup[key])
                                                    if (key !== "new" && key !== "index") return (
                                                        <Box sx={{ width: "48%" }} key={`box-${index}-${key}`}>
                                                            {
                                                                key === "primaryOri" && (
                                                                    <TextField
                                                                        required
                                                                        label={oriGroup[key].field}
                                                                        name={oriGroup[key].field}
                                                                        value={oriGroup[key].value}
                                                                        placeholder={oriGroup[key].placeholder}
                                                                        variant="outlined"
                                                                        sx={oriRfpField}
                                                                        error={!oriGroup[key].value && isError}
                                                                        helperText={!oriGroup[key].value && isError ? `Primary ORI field Cannot Be Empty ` : ""}
                                                                        onChange={(e: any) => {
                                                                            if (e.target.value === "") {
                                                                                setIsError(true)
                                                                            } else {
                                                                                setIsError(false);
                                                                            } 
                                                                            dispatchFormValues({ type: EditUserAction.ORI_RFP_GROUP, value: e.target.value, group: { index, key } })
                                                                        }} />
                                                                )
                                                            }
                                                            {
                                                                key === "secondaryOri" && (
                                                                    <TextField

                                                                        label={oriGroup[key].field}
                                                                        name={oriGroup[key].field}
                                                                        value={oriGroup[key].value}
                                                                        placeholder={oriGroup[key].placeholder}
                                                                        variant="outlined"
                                                                        sx={oriRfpField}
                                                                        error={!oriGroup[key].value && isError}
                                                                        helperText={!oriGroup[key].value && isError ? `Secondary ORI field Cannot Be Empty ` : ""}
                                                                        onChange={(e: any) => {
                                                                            if (e.target.value === "") {
                                                                                setIsError(true)
                                                                            } else {
                                                                                setIsError(false);
                                                                            } 
                                                                            dispatchFormValues({ type: EditUserAction.ORI_RFP_GROUP, value: e.target.value, group: { index, key } })
                                                                        }} />
                                                                )
                                                            }
                                                            {
                                                                key === "rfp" && (
                                                                    <Autocomplete
                                                                        id={`id-${key}`}
                                                                        sx={oriRfpField}
                                                                        options={rfpOptions}
                                                                        value={rfpOptions.find(option => option.rfp === oriGroup[key].value) || null}
                                                                        PaperComponent={CustomPaper}
                                                                        getOptionLabel={(option: any) => option.rfp}
                                                                        ListboxProps={{ style: { maxHeight: "35vh" } }}
                                                                        renderOption={(props, option: any) => (
                                                                            <MenuItem
                                                                                {...props}
                                                                                key={option.code}
                                                                                // value={oriGroup[key].value}
                                                                                sx={{
                                                                                    backgroundColor: 'background.default',
                                                                                    overflowY: 'auto',
                                                                                    wordWrap: 'break-word',
                                                                                    wordBreak: 'break-word',
                                                                                    whiteSpace: 'normal'
                                                                                }}
                                                                            >
                                                                                {option.rfp}
                                                                            </MenuItem>
                                                                        )}
                                                                        onChange={(e, newValue) => {
                                                                            if (!newValue) {
                                                                                setIsError(true)
                                                                            } else {
                                                                                setIsError(false);
                                                                            }
                                                                            dispatchFormValues({ type: EditUserAction.ORI_RFP_GROUP, value: newValue === null ? "" : newValue.rfp, group: { index, key } })
                                                                        }
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                required={oriGroup[key].required}
                                                                                label="RFP"
                                                                                placeholder={oriGroup[key].placeholder}
                                                                                variant="outlined"
                                                                                error={oriGroup[key].value === "" && isError}
                                                                                helperText={oriGroup[key].value === "" && isError ? "RFP field cannot be empty" : ""}
                                                                                // value={oriGroup[key].value}
                                                                                // value={rfpOptions.find(option => option.code === oriGroup[key].value) || null}
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            {oriRfpGroups.length === 2 && oriRfpGroups.some(group => group.new)
                                                                                                ? oriGroup.new && (
                                                                                                    <IconButton
                                                                                                        onClick={() => {
                                                                                                            dispatchFormValues({
                                                                                                                type: EditUserAction.REMOVE_ORI_RFP_GROUP,
                                                                                                                group: { index, key }
                                                                                                            })
                                                                                                        }
                                                                                                        }
                                                                                                        sx={{ zIndex: 5, position: "absolute", left: "91%", bottom: "87%", height: "28px", width: "28px" }}
                                                                                                    >
                                                                                                        <CancelIcon color='primary' />
                                                                                                    </IconButton>
                                                                                                )
                                                                                                : oriRfpGroups.length > 1 && (
                                                                                                    <IconButton
                                                                                                        onClick={() => {
                                                                                                            setRemovedOriRfp([...removedOriRfp, { removed: oriRfpGroups[index], index: oriRfpGroups[index].index}])

                                                                                                            dispatchFormValues({
                                                                                                                type: EditUserAction.REMOVE_ORI_RFP_GROUP,
                                                                                                                group: { index, key }
                                                                                                            })
                                                                                                            setEdited(true)
                                                                                                        }
                                                                                                        }
                                                                                                        sx={{ zIndex: 5, position: "absolute", left: "91%", bottom: "87%", height: "28px", width: "28px" }}
                                                                                                    >
                                                                                                        <CancelIcon color='primary' />
                                                                                                    </IconButton>
                                                                                                )
                                                                                            }
                                                                                            {params.InputProps.endAdornment}
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                            }
                                                        </Box>
                                                    )
                                                })

                                            }
                                        </InputWrapper>
                                    )
                                })
                            }
                        </Container>
                        <Container disableGutters sx={{ marginTop: "auto", display: "flex", width: "100%", height: "42px", justifyContent: "space-evenly" }}>
                            <Button onClick={() => cancelEdit()} variant="outlined" sx={{ width: "268px" }}>CANCEL</Button>
                            
                            <Button disabled={
                                !edited 
                                    ? true 
                                    : isError 
                                        ? true 
                                        : loading 
                                            ? true 
                                            : false
                                } 
                                onClick={handleUpdate} 
                                variant="contained" 
                                sx={{ width: "268px" }}>
                                {loading ? <CircularProgress size='1.5rem' sx={{ color: 'secondary' }} /> : "UPDATE"}
                            </Button>
                        </Container>
                    </Container>
                }
            </Container>
        </Container>
    )
}